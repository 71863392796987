.container {
  .title {
    letter-spacing: 1.5px;
    margin-bottom: 0.5rem;
    font-size: 2rem;
    color: #000;
    text-align: left;
    .purpleText {
      color: #9800ee;
    }
  }
  .headerLine {
    border-bottom: 2px solid gray;
    margin-bottom: 2rem;
  }
}

@primary-color: #4361ee;