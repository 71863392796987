.mintAddress {
  span {
    height: 100% !important;
  }
  input {
    height: 100% !important;
  }
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--main-background-color);
  border: 1px solid var(--main-border-color);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  overflow-y: auto;

  .title {
    letter-spacing: 1.5px;
    margin-bottom: 0.5rem;
    font-size: 2rem;
    color: #000;
    text-align: left;
    .purpleText {
      color: #9800ee;
    }
  }
  .headerLine {
    border-bottom: 2px solid gray;
    margin-bottom: 2rem;
  }
  .form {
    display: flex;
    column-gap: 3rem;
    .loadToken {
      flex: 3;
      display: flex;
      flex-direction: column;
      row-gap: 2rem;
      .inputBar {
        display: flex;
        column-gap: 2rem;
      }
      .tokenInfo {
        .cardContent {
          display: flex;
          column-gap: 2rem;
          align-items: center;
          justify-content: left;
          .imageContainer {
            width: 70px;
            height: 70px;
            img {
              width: 100%;
              height: 100%;
              border: 1px solid gray;
              border-radius: 50%;
            }
          }
          .textContainer {
            p {
              margin: 0px;
            }
          }
        }
      }
    }
    .takesnapshot {
      flex: 2;

      .cardContent {
        display: flex;
        flex-direction: column;
        row-gap: 2rem;
        .formField {
          p {
            font-size: 1rem;
          }
        }
      }
    }
  }
}

.infoContainer {
  overflow-y: auto;
}
.stickyContainer {
  position: sticky;
  top: 0;
  background: white;
  text-align: center;
  padding: 8px 0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
}

.stickyHeader {
  font-size: 1.25rem;
  font-weight: bold;
}

@primary-color: #4361ee;