.tableContainer {
  td,
  th {
    padding: 8px 8px !important;
  }
}
.infoContainer {
  .section {
  }
}

.mintAddress {
  font-size: 2rem;
  span {
    height: 100% !important;
  }
  input {
    height: 100% !important;
  }
}

@primary-color: #4361ee;