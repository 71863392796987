.faqHeading {
  font-size: 1rem;
  h2 {
    color: #9800ed;
    // margin-bottom: 1.1rem;
    // margin-top: 1.5rem;
  }
}
.faqOption {
  .collapsePannel {
    border-color: #9800ed;
    margin-bottom: 1.5rem;
  }
  .ant-collapse-item {
    border-color: #9800ed;
  }
  .ant-collapse-header,
  .ant-collapse-arrow {
    font-size: 1rem !important;
  }
  .ant-collapse-content-box {
    p {
      font-size: 0.95rem;
    }
  }
}

@primary-color: #4361ee;