.fieldContainer {
  width: 45%;
}
.fieldLabel {
  font-size: 1rem;
  padding-bottom: 0.3rem;
  padding-top: 1rem;
  font-weight: 400;
  margin: 0px;
}
.fieldInput {
  border: 1px solid var(--red-button-text-color);
  outline: none;
  box-shadow: none;
  &:hover {
    border: 1px solid transparent;
    box-shadow: 0 0 0 1px var(--red-button-text-color);
  }
  &:active {
    border: 1px solid transparent;
    box-shadow: 0 0 0 1px var(--red-button-text-color);
  }
  &:focus {
    border: 1px solid transparent;
    box-shadow: 0 0 0 1px var(--red-button-text-color);
  }
  &:focus-within {
    border: 1px solid transparent;
    box-shadow: 0 0 0 1px var(--red-button-text-color);
  }
}
.fullWidthField {
  width: 100%;
}
.mintForm {
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.actionButtonContainer {
  margin-top: 2.5rem;
  margin-bottom: 1rem;
  width: 100%;
  .createTokenButton {
    width: 100%;
    font-size: 1.5rem;
    height: 3rem;
  }
}
.uploadContainer {
  //   height: 8rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border: 1px solid var(--red-button-text-color);
  border-radius: 0.5rem;
  padding-block: 1rem;
  padding-inline: 0.5rem;
  column-gap: 3rem;
  flex-wrap: wrap;
  align-items: stretch;
  p {
    cursor: pointer;
  }
  .uploadBoxWrap {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    column-gap: 3rem;
    align-items: center;
    // padding-inline: 3rem;
    flex: 1;
    justify-content: center;

    span[role='button'] {
      display: flex;
      flex-direction: column;
      align-items: center;
      border: 1px dashed var(--red-button-text-color);
      border-radius: 1rem;
      padding-block: 1rem;
      padding-inline: 2rem;
    }
  }
  .uploadInfo {
    flex: 1;
    display: flex;
    align-items: center;

    p {
      margin-bottom: 0.3rem;
    }
    .infoWrap {
      width: calc(min(95%, 700px));
      border: 1px dashed #dacaff;
      padding-block: 0.5rem;
      padding-inline: 1rem;
      width: calc(min(95%, 700px));
      background: #dacaff;
      outline: 1px dashed #dacaff;
      outline-offset: 0.5rem;
      border-radius: 0.25rem;
    }
    .note {
      font-size: 1.2rem;
    }
    .mainText {
      font-size: 1rem;
    }
    .faded {
      opacity: 0.8;
      margin-top: 2rem;
    }
  }
}
@media only screen and (max-width: 1023px) {
  .uploadContainer {
    row-gap: 3rem;
    .uploadBoxWrap {
      //   width: 300px;
    }
    .uploadInfo {
      //   min-width: 300px;
      justify-content: center;
    }
  }
}
.uploadImageOR {
  text-align: center;
  font-size: 1rem;
  font-weight: 400;
  margin: 0.5rem;
}
.imagePreview {
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  img {
    height: 4rem;
    width: 4rem;
  }
}
.minFormTitle {
  letter-spacing: 1.5px;
  margin-bottom: 0.5rem;
  font-size: 2rem;
  color: #000;
  text-align: left;

  .highlighText {
    color: var(--red-button-text-color);
  }
}
.headerLine {
  border-bottom: 2px solid gray;
  margin-bottom: 2rem;
  margin-top: 2rem;
}
.primaryButton {
  background-color: var(--alert-info-bg-color);
  color: var(--active-menu-item-text);
  border-color: var(--active-menu-item-text);
  &:hover:not(:disabled) {
    border-color: var(--active-menu-item-text) !important;
    color: var(--active-menu-item-text) !important;
  }
}
.createTokenContainer {
  display: flex;
  flex-direction: column;
  row-gap: 3rem;
  background-color: var(--main-background-color);
  border: 1px solid var(--main-border-color);
  border-radius: 5px;
  padding: 1.5rem;

  .creationSection {
    display: flex;
    column-gap: 5%;
    align-items: stretch;
    .tokenCreationFormContainer {
      width: 60%;
      box-sizing: border-box;
    }
    .tokenCreationInfoContainer {
      background: #dacaff;
      border-radius: 20px;
      border: 2px solid;
      //   height: fit-content;
      margin-top: 5rem;
      display: flex;
      width: min(35%, 500px);
      box-sizing: border-box;
      flex-direction: column;
      padding-left: 2rem;
      padding-right: 2rem;
      padding-bottom: 3rem;
    }
  }
}

.mintContainer {
  background-color: var(--main-background-color);
  // border: 1px solid var(--main-border-color);
  // border-radius: 5px;
  padding: 1.5rem;
}
.mintSubcontainer {
  background-color: var(--main-background-color);
  // border: 1px solid var(--main-border-color);
  // border-radius: 5px;
  // padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  // border: 1px solid red;
  width: 100%;
  // gap: 5px;
}
.mintSubcontainer > div:first-child {
  // border: 1px solid blue;
  flex: 0 1 64%;
  min-width: 0;
}
.mintSubcontainer > div:nth-child(2) {
  // border: 1px solid blue;
  flex: 0 1 36%;
  min-width: 0;
}
.tokendetailscontainer {
  padding: 1rem;
  background-color: var(--main-background-color);
}
.infoAbout {
  margin-top: 2rem;
  p {
    margin: 0px;
    line-height: 2rem;
    font-size: 1rem;
  }
}
.infoHowTo {
  margin-top: 2rem;
  li {
    margin-bottom: 1rem;
  }
}
.generateURL {
  width: 100%;
  margin: 0px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.defaultButton {
  color: var(--red-button-text-color);
  border-color: var(--red-button-text-color);
  &:active {
    color: var(--red-button-text-color) !important;
    border-color: var(--red-button-text-color) !important;
  }
  &:hover {
    color: var(--red-button-text-color) !important;
    border-color: var(--red-button-text-color) !important;
    opacity: 0.8 !important;
  }
}
.uploadMetadata {
  width: 100%;
  margin-top: 2rem;
  background-color: var(--red-button-bg-color);
}

.socialMediaContainer {
  .enableCheckbox {
    padding-top: 1rem;
    padding-bottom: 0.4rem;
  }
  .mediaLinksContainer {
    // margin-top: 0.5rem;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    background: #f6f6f6;
    padding: 1rem;
    border-radius: 10px;

    .mediaLinkItem {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.5rem;
      //   p {
      //     margin: 0px;
      //     font-size: 0.8rem;
      //     margin-right: 2rem;
      //   }
      .fieldInput {
        border: 1px solid var(--red-button-text-color);
        box-shadow: none;
        max-width: 300px;
        &:hover {
          // border: 0px;
          box-shadow: 0 0 0 1px var(--red-button-text-color);
        }
        &:active {
          // border: 0px;
          box-shadow: 0 0 0 1px var(--red-button-text-color);
        }
        &:focus {
          // border: 0px;
          box-shadow: 0 0 0 1px var(--red-button-text-color);
        }
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .fieldContainer {
    width: 100%;
  }
  .createTokenContainer {
    display: flex;
    flex-direction: column;

    .creationSection {
      display: flex;
      flex-direction: column;

      .tokenCreationFormContainer {
        width: 100%;
        box-sizing: border-box;
      }
      .tokenCreationInfoContainer {
        display: flex;
        width: 100%;
        box-sizing: border-box;
        flex-direction: column;
      }
    }
  }
}

// Display Token css
.tokensConatiner {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  .tokenItem {
    width: 33%;
    padding: 0.75rem;
    box-sizing: border-box;
    .tokenCard {
      background: var(--main-background-color);
      color: var(--main-text-color);
      border: 1px solid var(--main-border-color);
      border-radius: 0.25rem;
      box-shadow:
        0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
        0 0 0 1px rgba(10, 10, 10, 0.02);
      color: #4a4a4a;
      max-width: 100%;
      position: relative;

      .tokenCardContent {
        box-sizing: border-box;
        padding: 1.5rem;
        background-color: transparent;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        row-gap: 1rem;
        display: flex;
        flex-direction: column;
        .mainDetails {
          display: flex;
          .mediaSection {
            width: 56px;
            height: 56px;
            // border: 1px solid rgba(249, 81, 146, 0.2);
            img {
              width: 56px;
              height: 56px;
              border-radius: 50%;
              // border: 2px solid rgba(249, 81, 146, 0.2);
            }
          }
          .textSection {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            padding-left: 1.5rem;
            .cardTitle {
              font-size: 22px;
              font-weight: 600;
              margin: 0;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              max-width: 250px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            .cardDescription {
              font-size: 15px;
              font-weight: 600;
              color: #888;
              margin: 0 !important;
            }
          }
        }
        .metaDetails {
          display: flex;
          flex-direction: column;
          padding-top: 1rem;
          padding-bottom: 1rem;
          .metaDetailsItem {
            display: flex;
            justify-content: space-between;
            p {
              margin: 0px;
              max-width: 150px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }
}
.tokenSearchContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .inputTokenSearch {
    // border: 1px solid var(--red-button-text-color);
    box-shadow: none;
    max-width: 300px;
    &:hover {
      // border: 0px;
      box-shadow: 0 0 0 1px var(--active-menu-item-text);
      border: 1px solid transparent;
    }
    &:active {
      // border: 0px;
      box-shadow: 0 0 0 1px var(--active-menu-item-text);
      border: 1px solid transparent;
    }
    &:focus {
      // border: 0px;
      box-shadow: 0 0 0 1px var(--active-menu-item-text);
      border: 1px solid transparent;
    }
  }
}
.tokenPageTitleConatiner {
  .tokenPageTitle {
    color: var(--red-button-text-color);
    margin-left: 2rem;
  }
}
.mintAddress {
  display: flex;
  column-gap: 3rem;
  .mintAddressInput {
    font-size: 2rem;
    span {
      height: 100% !important;
    }
    input {
      height: 100% !important;
    }
  }
}
.myTokensPageContainer {
  display: flex;
  flex-direction: column;
  background-color: var(--main-background-color);
  border: 1px solid var(--main-border-color);
  border-radius: 5px;
  padding: 1.5rem;
  row-gap: 2rem;
}
@media only screen and (max-width: 1023px) {
  .myTokensPageContainer {
    // margin: auto;
  }
}

.updateInfoButton {
  width: 100%;
}
@media only screen and (max-width: 1023px) {
  .tokensConatiner {
    .tokenItem {
      width: 100%;
    }
    .textSection {
      padding: 0.5rem !important;
      .cardTitle {
        font-size: 14px !important;
        max-width: 100px !important;
      }
    }
  }
}

// connect wallet css

.connectWalletConatiner {
  width: 55%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  border: 1px solid var(--main-border-color);
  border-radius: 10px;
  padding: 1.5rem;
  h1 {
    color: var(--red-button-text-color);
    font-size: 1.5rem;
  }
  p {
    font-size: 1.3rem;
    text-align: justify;
  }
}
@media only screen and (max-width: 1023px) {
  .connectWalletConatiner {
    width: 100%;
  }
}

// token Basic Details css
.tokenDetailsContainer {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;

  background-color: var(--main-background-color);
  border: 1px solid var(--main-border-color);
  border-radius: 5px;
  padding: 1rem;
}
.tokenDetailsContainers {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;

  background-color: var(--main-background-color);
  border: 1px solid var(--main-border-color);
  border-radius: 5px;
  padding: 1rem;
  margin-bottom: 1rem;
}
.tokenmetadatcontainer {
  background-color: var(--main-background-color);
  padding: 1rem;
}
.tokenBasicDetails {
  .cardContent {
    display: flex;
    justify-content: space-between;
    // border: 1px solid var(--main-border-color);
    .leftContainer {
      display: flex;
      justify-content: left;
      gap: 1rem;
      width: 100%;
      .imageContainer {
        width: 75px;
        height: 75px;
        flex-shrink: 0;
        img {
          width: 100%;
          height: 100%;
          border: 1px solid gray;
          border-radius: 50%;
        }
      }
      .textContainer {
        display: flex;
        flex-direction: column;

        justify-content: space-between;
      }
    }
  }
  p {
    margin: 0px;
  }
}
@media only screen and (max-width: 1023px) {
  .tokenBasicDetails {
    .cardContent {
      gap: 1rem;
      flex-direction: column;
      .leftContainer {
        .textContainer {
          width: 75%;
        }
      }
    }
  }
}

// token details tab
.tabContainer {
  div[role='tablist'] {
    margin-bottom: 0px;
  }
  div[role='tab'] {
    font-size: 1.3rem;
    padding-bottom: 0.3rem;
  }
}

// tokenDetailsContent

.tokenDetailsContent {
  display: flex;
  flex-direction: column;
  .detailsRow {
    overflow: hidden;
  }
  .detailsRowColumn {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  p {
    margin: 0px;
  }
}

// tokenBurnContent
.tokenBurnContent,
.tokenMintContent,
.tokenMintAuthorityContent,
.tokenFreezeAuthorityContent {
  display: flex;
  justify-content: center;
  //   margin-top: 2rem;
  //   margin-bottom: 2rem;
  // border: 1px solid red;
  width: 100%;
  gap: 3rem;
  .burnCard {
    width: 40%;
    box-shadow:
      0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
      0 0 0 1px rgba(10, 10, 10, 0.02);
  }

  .inputBurnTokenContainer {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    margin-bottom: 2rem;
    border-radius: 5px;
    border: 1px solid gainsboro;
    padding-left: 0.5rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    &:hover {
      // border: 0px;
      box-shadow: 0 0 0 1px var(--active-menu-item-text);
      border: 1px solid transparent;
    }
    &:active {
      // border: 0px;
      box-shadow: 0 0 0 1px var(--active-menu-item-text);
      border: 1px solid transparent;
    }
    &:focus {
      // border: 0px;
      box-shadow: 0 0 0 1px var(--active-menu-item-text);
      border: 1px solid transparent;
    }
    p {
      margin: 0px;
    }
    .youBurn,
    .walletBalance {
      box-sizing: border-box;
      margin: 0px;
      min-width: 0px;
      font-weight: 485;
      font-size: 12px;
      color: rgb(125, 125, 125);
    }
    .tokenInputAmoutContainer {
      display: flex;
      justify-content: space-between;
      .inputBurn {
        border: 0px;
        font-size: 1.3rem;
        box-shadow: none;
        max-width: 100%;
        padding-left: 0px;
      }

      .tokenTickerInfo {
        display: flex;
        height: 100%;
        margin-bottom: auto;
        margin-top: auto;
        align-items: center;
        gap: 0.8rem;
        border: 1px solid gainsboro;
        padding-left: 0.5rem;
        width: 50%;
        padding-right: 0.5rem;
        border-radius: 20px;
        font-size: 1.3rem;
        img {
          width: 20px;
          height: 20px;
          border-radius: 50%;
        }
        span {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .burnTokenBtn {
    width: 100%;
    height: 100%;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1.3rem;
  }
}

.tokenBurnContent.increasedWidth .burnCard {
  width: 90%;
}
.tokenMintContent.increasedWidth .burnCard {
  width: 90%;
}
.tokenMintAuthorityContent.increasedWidth .burnCard {
  width: 90%;
}
.tokenFreezeAuthorityContent.increasedWidth .burnCard {
  width: 90%;
}

@media only screen and (max-width: 1023px) {
  .mintSubcontainer {
    flex-direction: column;
    row-gap: 2rem;
    .tokenBurnContent,
    .tokenMintContent,
    .tokenMintAuthorityContent,
    .tokenFreezeAuthorityContent {
      .burnCard {
        width: 100%;
      }
    }
  }
}

// mint authority
.tokenMintAuthorityContent,
.tokenFreezeAuthorityContent {
  .burnCardInput {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    > div:last-child {
      margin-bottom: 3rem;
    }
    .transferTo {
      text-align: center;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
    .inputBurnTokenContainer {
      margin-bottom: 0px;
      .tokenTickerInfo {
        span {
          font-size: 1rem;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

// metadata update

.tokenMetadataContent {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  .burnCard {
    width: 60%;
    .metadataInput {
      display: flex;
      flex-direction: column;
      .metadataInputRow {
        margin-bottom: 0.7rem;
        align-items: center;
      }
      .metadataInputRowCol {
        padding-top: 0rem;
        padding-bottom: 0rem;
        padding-left: 1rem;
        padding-right: 1rem;
      }
      p {
        margin: 0px;
      }
    }
  }
  .updateBtnContainer {
    display: flex;
    justify-content: center;
    .updateMetadata {
      width: 100%;
      width: 100%;
      height: 100%;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      padding-left: 1rem;
      padding-right: 1rem;
      font-size: 1.3rem;
    }
  }
}
@media only screen and (max-width: 1023px) {
  .tokenMetadataContent {
    .burnCard {
      width: 100%;
    }
  }
}

// copy text
.simpleCopyText {
  display: flex;
  font-size: 0.8rem;
  column-gap: 0.5rem;
  cursor: pointer;
  > span {
    display: flex;
    width: 100%;
  }
  .copyData {
    // flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .copyIcon {
    flex-shrink: 0;
  }
}
.copyRedirect {
  display: flex;
  .data {
    // flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .copy {
    flex-shrink: 0;
    padding-left: 0.3rem;
  }
  .redirect {
    flex-shrink: 0;
  }
}

// custom address container

.customAddressContainer {
  display: flex;
  flex-direction: column;
  background: #f6f6f6;
  padding: 24px;
  border-radius: 0.5rem;

  max-width: 700px;
  .prefixSuffix {
    display: flex;
    flex-direction: row;
    column-gap: 2rem;
    justify-content: space-between;
    .addressItem {
      flex: 1;
    }
  }

  .example {
    display: flex;
    margin-top: 0.5rem;
    color: #0009;
  }
  .caseSensitive {
    align-items: center;
    display: flex;
    gap: 24px;
    margin-bottom: 1.5rem;
    margin-top: 0.5rem;
  }
  .threads {
    width: 100%;
    margin-bottom: 1.5rem;
    p {
      margin-bottom: 0.5rem;
      width: 100%;
    }
  }
  .generate {
    display: flex;
    column-gap: 3rem;
    justify-content: space-around;
    button {
      flex: 1;
    }
  }
  .boldText {
    color: #000;
  }
}
.customAddressContainers {
  display: flex;
  flex-direction: column;
  background: #f6f6f6;
  padding: 24px;
  border-radius: 0.5rem;

  //   max-width: 900px;
  .prefixSuffix {
    display: flex;
    flex-direction: row;
    column-gap: 2rem;
    justify-content: space-between;
    .addressItem {
      flex: 1;
    }
  }

  .example {
    display: flex;
    margin-top: 0.5rem;
    color: #0009;
  }
  .caseSensitive {
    align-items: center;
    display: flex;
    gap: 24px;
    margin-bottom: 1.5rem;
    margin-top: 0.5rem;
  }
  .threads {
    width: 100%;
    margin-bottom: 1.5rem;
    p {
      margin-bottom: 0.5rem;
      width: 100%;
    }
  }
  .generate {
    display: flex;
    column-gap: 3rem;
    justify-content: space-around;
    button {
      flex: 1;
    }
  }
  .boldText {
    color: #000;
  }
}
.updateCardMessage {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  p {
    word-break: break-all;
  }
}
.updateCardh2 {
  text-align: center;
}

@primary-color: #4361ee;