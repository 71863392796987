// .ant-select {
//   font-family: 'Bebas Neue', sans-serif;
//   font-weight: 100;
//   font-size: 1.5rem;
//   color: #9800ed;
//   height: 2.75rem !important;
// }
// .ant-select-selector {
//   box-shadow: -4px 4px #000 !important;
//   border: 1px solid #000 !important;
//   font-size: 1.5rem !important;
//   height: 2.75rem !important;
//   padding-inline: 1rem !important;
// }
// .ant-select-arrow {
//   color: #000 !important;
// }
// .ant-select-dropdown {
//   font-family: 'Bebas Neue', sans-serif;
//   font-weight: 100;
//   border-bottom: 2px solid #000 !important;
//   border-left: 2px solid #000 !important;
//   box-shadow: -4px 4px #000 !important;
// }
// .ant-select-item-option {
//   font-size: 1.3rem !important;
//   letter-spacing: 1px;
// }

// step css
.ant-steps-item-finish {
  .ant-steps-item-icon {
    background-color: #9800ed !important;
    border-color: #9800ed !important;
    .ant-steps-icon {
      svg {
        fill: #fff;
      }
    }
  }
  .ant-steps-item-content {
  }
}
.ant-steps-item-active {
  .ant-steps-item-icon {
    background-color: #9800ed !important;
    border-color: #9800ed !important;
    .ant-steps-icon {
    }
  }
  .ant-steps-item-content {
  }
}
.ant-steps-item-wait {
}

// burner search
.burnertokenload {
  .ant-select {
    font-family: 'Bebas Neue', sans-serif;
    font-weight: 100;
    font-size: 1.5rem;
    color: #9800ed;
    height: 3rem !important;
  }
  .ant-select-selector {
    box-shadow: 0px 0px #000 !important;
    border: 0px solid #000 !important;
    font-size: 1.5rem !important;
    height: 3rem !important;
    padding-inline: 0rem !important;
  }
}
.customAutoComplete {
  font-family: 'Bebas Neue', sans-serif;
  font-weight: 100;
  border-bottom: 0px solid #000 !important;
  border-left: 0px solid #000 !important;
  box-shadow: 0px 0px #000 !important;
  border: 1px solid #000 !important;

  .ant-select-item-option {
    font-size: 1.3rem !important;
    letter-spacing: 1px;
  }
  .ant-select-arrow {
    color: #000 !important;
  }
}

.ant-tooltip {
  max-width: 300px; //default is 250 by antD
  .ant-tooltip-inner {
    background-color: rgba(0, 0, 0, 0.9);
    font-size: 10px;
    border-radius: 4px;
    min-width: 0;
    min-height: 0;
  }
}

.shaded-popover .ant-popover-content .ant-popover-inner {
  background: linear-gradient(180deg, var(--faded-color), #fff);
  border: 1px solid var(--faded-color);
  box-shadow: 0 10px 20px 0 #00000040;
  padding: 24px;
}
.fee-popover .ant-popover-content .ant-popover-inner {
  width: 530px;
}
.rpc-popover .ant-popover-content .ant-popover-inner {
  width: 460px;
}

.rpc-option.ant-radio-wrapper {
  > span:nth-child(2) {
    padding-inline-end: 0;
    flex: 1;
  }
}

.modifiedSelect {
  .ant-select {
    font-family: 'Bebas Neue', sans-serif;
    font-weight: 100;
    font-size: 1.5rem;
    color: #9800ed;
    height: 2.75rem !important;
  }
  .ant-select-selector {
    box-shadow: -4px 4px #000 !important;
    border: 1px solid #000 !important;
    font-size: 1.5rem !important;
    height: 2.75rem !important;
    padding-inline: 1rem !important;
  }
  .ant-select-arrow {
    color: #000 !important;
  }
  .ant-select-dropdown {
    font-family: 'Bebas Neue', sans-serif;
    font-weight: 100;
    border-bottom: 2px solid #000 !important;
    border-left: 2px solid #000 !important;
    box-shadow: -4px 4px #000 !important;
  }
  .ant-select-item-option {
    font-size: 1.3rem !important;
    letter-spacing: 1px;
    font-family: 'Bebas Neue', sans-serif;
    font-weight: 100;
  }
}
.liquidityPoolSelect {
  .ant-select {
    font-family: Outfit;
    font-weight: 100;
    font-size: 1rem;
    color: #9800ed;
    height: 2.75rem !important;
  }
  .ant-select-selection-placeholder {
    font-family: Outfit;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 300;
    font-size: 1rem;
  }
  .ant-select-selector {
    font-size: 1rem !important;
    height: 2.75rem !important;
    padding-inline: 1rem !important;
  }
  .ant-select-arrow {
    color: #000 !important;
  }
  .ant-select-dropdown {
    font-family: 'Bebas Neue', sans-serif;
    font-weight: 100;
    border-bottom: 2px solid #000 !important;
    border-left: 2px solid #000 !important;
    box-shadow: -4px 4px #000 !important;
  }
  .ant-select-item-option {
    font-size: 1rem !important;
    letter-spacing: 1px;
    font-family: 'Bebas Neue', sans-serif;
    font-weight: 100;
    color: rgba(0, 0, 0, 0.8);
  }
}
.sider-icon {
  svg {
    width: 20px;
    height: 20px;
  }
  img {
    width: 20px;
    height: 20px;
  }
}

@primary-color: #4361ee;