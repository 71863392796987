.homeContainer {
  padding-bottom: 2rem;
  max-width: 1500px;
  margin: auto;
  .homeMainContent {
    display: flex;
    flex-direction: column;
    margin-top: 2.5rem;
    margin-bottom: 3rem;
    align-items: center;
    /* max-width: 800px; */
    justify-content: center;
    h1 {
      font-size: 2.5rem;
    }
    p {
      max-width: 700px;
      text-align: center;
      font-size: 1.1rem;
      font-weight: 300;
      margin-top: 2rem;
    }
  }
  .cardsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 3rem 5rem;
    justify-content: center;

    .cardWrap {
      width: 300px;
      height: 200px;
      .card {
        width: 100%;
        height: 100%;
        box-shadow: -6px 6px 6px #000;
        border: 1px solid;
        transition:
          transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1),
          box-shadow 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
        &:hover {
          box-shadow: -4px 4px 4px #000;
          cursor: pointer;
        }

        .cardContent {
          display: flex;
          flex-direction: column;
          .cardContentIcon {
            font-size: 1.5rem;
            color: #9800ed;
            display: flex;
            justify-content: center;

            span {
              padding: 1rem;
              border: 1px solid #9800ed;
              border-radius: 100%;
            }
          }
          h3 {
            margin-top: 1rem;
            text-align: center;
          }
          p {
            font-weight: 300;
            text-align: center;
            color: #000;
            opacity: 0.8;
          }
        }
      }
    }
  }
}

@primary-color: #4361ee;