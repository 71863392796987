// copy text
.simpleCopyText {
  display: inline-block;
  font-size: 0.8rem;
  column-gap: 0.5rem;
  cursor: pointer;
  > span {
    display: flex;
    width: 100%;
  }
  .copyData {
    // flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .copyIcon {
    flex-shrink: 0;
  }
}

@primary-color: #4361ee;