.navbarContainer {
  .navbarChain {
    display: flex;
    align-items: center;
    border: 1px solid var(--network-button-bg-color);
    background-color: var(--network-button-bg-color);
    padding: 4px 8px;
    border-radius: 30px;
    cursor: pointer;
    height: 40px;
    color: var(--text-color);
    margin-right: 8px;
    font-weight: 600;
    font-size: 1rem;
    img {
      width: 24px;
    }
    span {
      margin-inline: 0.5rem !important;
      color: var(--red-button-text-color);
    }
  }
  .navbarConnect {
    border-radius: 16px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    letter-spacing: 0.03em;
    line-height: 1;
    outline: 0;
    background-color: var(--red-button-bg-color);
    color: var(--red-button-text-color);
    line-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media only screen and (max-width: 1023px) {
  .navbarContainer {
    .ant-select {
      font-size: 1rem;
      color: #9800ed;
      height: 2.5rem !important;
    }
    .ant-select-selector {
      box-shadow: -2px 2px #000 !important;
      border: 1px solid #000 !important;
      font-size: 1rem !important;
      height: 2.5rem !important;
    }
    .ant-select-dropdown {
      font-weight: 100;
      border-bottom: 1px solid #000 !important;
      border-left: 1px solid #000 !important;
      box-shadow: -2px 2px #000 !important;
    }
    .ant-select-item-option {
      font-size: 1rem !important;
      letter-spacing: 1px;
    }
  }
}

@primary-color: #4361ee;