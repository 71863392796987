.tableContainer {
  td,
  th {
    padding: 8px 8px !important;
  }
}
.infoContainer {
  .section {
  }
}

.mintAddress {
  font-size: 2rem;
  span {
    height: 100% !important;
  }
  input {
    height: 100% !important;
  }
}

.tutorial {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  column-gap: 3rem;
  .textContent {
    flex: 1;
  }
  .videoContent {
    flex: 1;
  }
}
@media only screen and (max-width: 767px) {
  .mintAddress {
    font-size: 1rem;
  }
  .tutorial {
    flex-direction: column-reverse;
    row-gap: 3rem;
    .textContent {
      width: 100%;
    }
    .videoContent {
      width: 100%;
    }
  }
}

@primary-color: #4361ee;