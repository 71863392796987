.customAddressContainer {
  display: flex;
  flex-direction: column;
  background: #f6f6f6;
  padding: 24px;
  border-radius: 0.5rem;

  // max-width: 700px;
  .prefixSuffix {
    display: flex;
    flex-direction: row;
    column-gap: 2rem;
    justify-content: space-between;
    .addressItem {
      flex: 1;
    }
  }

  .example {
    display: flex;
    margin-top: 0.5rem;
    color: #0009;
  }
  .caseSensitive {
    align-items: center;
    display: flex;
    gap: 24px;
    margin-bottom: 1.5rem;
    margin-top: 0.5rem;
  }
  .threads {
    width: 100%;
    margin-bottom: 1.5rem;
    p {
      margin-bottom: 0.5rem;
      width: 100%;
    }
  }
  .generate {
    display: flex;
    column-gap: 3rem;
    justify-content: space-around;
    button {
      flex: 1;
    }
  }
  .boldText {
    color: #000;
  }
}
.customAddressContainers {
  display: flex;
  flex-direction: column;
  background: #f6f6f6;
  padding: 24px;
  border-radius: 0.5rem;

  //   max-width: 900px;
  .prefixSuffix {
    display: flex;
    flex-direction: row;
    column-gap: 2rem;
    justify-content: space-between;
    .addressItem {
      flex: 1;
    }
  }

  .example {
    display: flex;
    margin-top: 0.5rem;
    color: #0009;
  }
  .caseSensitive {
    align-items: center;
    display: flex;
    gap: 24px;
    margin-bottom: 1.5rem;
    margin-top: 0.5rem;
  }
  .threads {
    width: 100%;
    margin-bottom: 1.5rem;
    p {
      margin-bottom: 0.5rem;
      width: 100%;
    }
  }
  .generate {
    display: flex;
    column-gap: 3rem;
    justify-content: space-around;
    button {
      flex: 1;
    }
  }
  .boldText {
    color: #000;
  }
}

@primary-color: #4361ee;