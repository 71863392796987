.revokeCards {
  display: flex;
  column-gap: 1rem;
  row-gap: 1rem;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 1.5rem;
  justify-content: space-around;
  .cardd {
    background: #f6f6f6;
    border-radius: 5px;
    min-height: 185px;
    padding-left: 16px;
    padding-right: 16px;
    width: calc(max(25%, 220px));

    .ant-card-head {
      padding: 0.25rem;
    }
    .ant-card-body {
      padding: 0.25rem;
    }
  }
}
@media only screen and (max-width: 1023px) {
  .revokeCards {
    .cardd {
      width: calc(max(100%, 220px));
    }
  }
}

@primary-color: #4361ee;