.container {
  display: flex;
  justify-content: center;
  align-items: stretch;
  background-color: var(--main-background-color);
  border: 1px solid var(--main-border-color);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;

  .formContainer {
    width: 100%;
    background-color: #fff;
    border-radius: 1rem;
    .formTitle {
      .pupleText {
        color: #9800ee;
      }
      letter-spacing: 1.5px;
      margin-bottom: 0.5rem;
      font-size: 2rem;
      color: #000;
      //   text-decoration: underline;
    }
  }
  .headerLine {
    border-bottom: 2px solid gray;
    margin-bottom: 1rem;
    margin-top: 2rem;
    //   border: 1px solid red;
  }
}

@primary-color: #4361ee;