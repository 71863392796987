.trending-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  overflow-x: auto;
  white-space: nowrap;
  width: 100%;
}
.trending-items {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  align-items: center;
  flex: 1;
  background-color: black;
  color: white;
}

.token-item {
  display: flex;
  align-items: center;
  margin-right: 15px;
  color: white;
  font-family: 'Arial', sans-serif;
}

.token-icon {
  width: 25px;
  height: 25px;
  margin-right: 5px;
  border-radius: 50%;
}

.icon-btn img {
  width: 30px;
  height: 30px;
  background-color: #ff6600;
  border-radius: 50%;
  padding: 5px;
}

@primary-color: #4361ee;