.scanContainer {
  display: flex;
  flex-direction: column;
  row-gap: 4rem;
  background-color: var(--main-background-color);
  border: 1px solid var(--main-border-color);
  border-radius: 5px;
  padding: 1.5rem;
  min-height: 100dvh;

  .searchContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    .searchBar {
      max-width: 700px;
      min-width: 700px;
      .searchInput {
        width: 100%;
        display: flex;
        justify-content: space-between;
        font-size: 1.5rem;
        color: gray;
        border: 1px solid gray;
        border-radius: 5px;
        align-items: center;
        padding: 0.8rem 1rem;
        cursor: pointer;
        box-shadow: -6px 6px #000;
        border: 1px solid;
        transition:
          transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1),
          box-shadow 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);

        p {
          margin: 0;
          font-weight: 400;
          font-size: 1.2rem;
        }
        &:hover {
          // background-color: whitesmoke;
          box-shadow: -3px 3px #000;
        }
      }
    }
  }

  .title {
    letter-spacing: 1.5px;
    margin-bottom: 0.5rem;
    font-size: 2rem;
    color: #000;
    text-align: left;
    .purpleText {
      color: #9800ee;
    }
  }
  .headerLine {
    border-bottom: 2px solid gray;
    margin-bottom: 0rem;
  }
}

.cardContainer {
  display: flex;
  flex-wrap: wrap;
  row-gap: 3rem;
  column-gap: 3rem;
  justify-content: center;
}
.cardWrap {
  width: 250px;
  height: 180px;
  .card {
    width: 100%;
    height: 100%;
    box-shadow: -8px 8px #000;
    border: 1px solid;
    transition:
      transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1),
      box-shadow 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
    &:hover {
      box-shadow: -4px 4px #000;
      cursor: pointer;
    }

    .cardContent {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 0.5rem;
      // row-gap: 0.3rem;
      img {
        border-radius: 50%;
      }
      h3 {
        margin-bottom: 0.2rem;
        text-align: center;
      }
      p {
        font-weight: 300;
        text-align: center;
        color: #000;
        opacity: 0.8;
      }
      .socials {
        display: flex;
        column-gap: 1rem;
        margin-top: 0.8rem;
      }
    }
  }
}
.genearateGraph {
  .card {
    display: flex;
    column-gap: 2rem;
    align-items: center;
    cursor: pointer;
    padding: 1rem 0.5rem;
    img {
      border-radius: 50%;
    }

    .textContent {
      display: flex;
      column-gap: 2rem;
      flex: 1;
      padding-right: 0.5rem;
      justify-content: space-between;
    }
    &:hover {
      background-color: whitesmoke;
    }
  }
}
.cardListView {
  display: flex;
  flex-direction: column;
  padding-top: 0rem;
  .card {
    display: flex;
    column-gap: 2rem;
    align-items: center;
    border-bottom: 1px solid gray;
    cursor: pointer;
    padding: 1rem 0.5rem;
    img {
      border-radius: 50%;
    }

    .textContent {
      display: flex;
      column-gap: 2rem;
      flex: 1;
      justify-content: space-between;
    }
    &:hover {
      background-color: whitesmoke;
    }
  }
}

@primary-color: #4361ee;